<template>
    <div class="col-12 md:col-6 lg:col-6 xl:col-4 icon-button" @click="$router.push({ name: 'AgenciaConceito_CheckIns_QRCode_Ler' })" v-if="$temAcessoView('AGECON-CHECKIN-02')">
        <div class="overview-box card">
            <div class="flex pt-2 pb-2">
                <i class="pi pi-fw pi-qrcode iconeColor" style="font-size: 5rem"></i>
                <div class="flex flex-column">
                    <div><span style="font-size: 1.5rem">Agência Conceito</span></div>
                    <div class="mt-2">
                        <span style="font-size: 2rem"><strong>CheckIn QRCode</strong></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
.iconeColor {
    color: rgb(65, 140, 226) !important;
}
</style>
