<template>
    <div class="grid layout-dashboard">
        <agencia-conceito-checkin-qrcode></agencia-conceito-checkin-qrcode>
    </div>
</template>

<script>
import AgenciaConceitoCheckinQrcode from '../agenciaconceito/checkins/qrcode/DashBoard';

export default {
    components: {
        AgenciaConceitoCheckinQrcode,
    },
};
</script>